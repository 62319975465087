.bakgrunn img {
  width: 100%;
}
.openclose {
  position: absolute;
  right: 0;  
  bottom: 0;
  margin: 0 2rem 4rem 0;
  background: none;
  border-radius: 2rem;
  outline: none;
}
.supportai-chatbutton button {
  background: green;
}
.arrow-up {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 55px;
  cursor: pointer;
  padding: 0.1rem;
  border-radius: 2rem;
  background: white;
  box-shadow: 0 5px 36px rgba(0, 0, 0, 0.25);
  transform: rotate(-90deg);
}
.arrow-down {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 55px;
  cursor: pointer;
  padding: 0.1rem;
  border-radius: 2rem;
  background: white;
  box-shadow: 0 5px 36px rgba(0, 0, 0, 0.25);
  transform: rotate(90deg);
}
.supportai-container {
  position: fixed;
  bottom: 124px;
  right: 20px;
  height: min(704px, 100% - 184px);
  min-height: 80px;
  width: 400px;
  max-height: 704px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.26) 0px 5px 40px;
  border-radius: 16px;
  overflow: hidden;
}
.supportai-header {
  z-index: 1;
  position: absolute;
  min-height: 180px;
  max-height: 180px;
  background-color: var(--color-dark);
  top: 0;
  right: 0;
  left: 0;
}
.supportai-nav {
  display: flex;
  -webkit-box-flex: 1;
  flex-grow: 1;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  justify-content: center;
  min-height: 48px;
  font-size: 18px;
  color: rgb(255, 255, 255);
}
.supportai-nav h1 {
  font-family: var(--font-family);
  margin-top: 2rem;
  font-size: 22px;
  margin-bottom: 1.5rem;
}
.supportai-nav p {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 100;
  text-align: left;
  line-height: 1.3rem;
  margin: 0 2rem;
}
.supportai-nav p span {
  font-size: 18px;
}
.supportai-body {
  z-index: 0;
  margin-top: 180px;
  position: relative;
  justify-content: center;
  align-items: center;
  inset: 0;
  height: 47vh;
  overflow-y: scroll;
}
.supportai-body__typing {
  position: fixed;
  bottom: 230px;
  margin-left: 10px;
}
.message-box {
  background: #e4e4e4;
  padding: 1rem;
  border-radius: 0.5rem;
  bottom: 0;
  margin: 1rem;
}
.message-box p {
  font-family: var(--font-family);
  font-size: 14px;
}
.message-box button {
  padding: 0.8rem;
  background-color: #303030;
  background-image: url(https://assets-global.website-files.com/5bbfaf3252489b4c484ba9b9/638f56620bf8b035015567e4_noise.png);
  background-position: 0 0;
  background-size: 200px;
  color: white;
  outline: none;
  border: none;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  border-radius: 8px;
  margin: 1rem 0 0;
  transition: transform .2s cubic-bezier(.645, .045, .355, 1),border-color .2s cubic-bezier(.645, .045, .355, 1),border-radius .2s cubic-bezier(.645, .045, .355, 1),box-shadow .2s cubic-bezier(.645, .045, .355, 1),background-color .2s cubic-bezier(.645, .045, .355, 1),-webkit-transform .2s cubic-bezier(.645, .045, .355, 1);
}
.message-box button:hover {
  background-color: #141414;
  -webkit-transform: translate(0,2px);
  -ms-transform: translate(0,2px);
  transform: translate(0,2px);
  color: #fff;
  transition: transform .2s cubic-bezier(.645, .045, .355, 1),border-color .2s cubic-bezier(.645, .045, .355, 1),border-radius .2s cubic-bezier(.645, .045, .355, 1),box-shadow .2s cubic-bezier(.645, .045, .355, 1),background-color .2s cubic-bezier(.645, .045, .355, 1),-webkit-transform .2s cubic-bezier(.645, .045, .355, 1);
}
.type::after {
  content: '';
  animation: none;
}

@keyframes cursor {
  50% {
    opacity: 0;
  }
}
.supportai-inputarea {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  min-height: 56px;
  max-height: 200px;
  border-radius: 10px;
  border-top: 1px solid rgba(0, 0, 0, 0.08);
}
.supportai-inputarea input {
  position: absolute;
  bottom: 0;
  left: 0;
  resize: none;
  border: none;
  outline: none;
  overflow-y: auto;
  box-sizing: border-box;
  padding: 18px 45px 18px 29px;
  width: 100%;
  height: 100%;
  font-family: var(--font-family);
  font-size: 14px;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  max-height: 200px;
}
.supportai-inputarea button {
  z-index: 1;
  position: absolute;
  background: none;
  padding: 1rem;
  border: none;
  bottom: 0;
  right: 0;
  cursor: pointer;
  transform: rotate(90deg);
}
.me {
  background-color: #e1f4ff;
  margin-left: 3rem;
}
.ai {
  margin-right: 3rem;
}