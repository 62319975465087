.supportai__login {
  display: flex;
  flex-direction: column;
  height: 70vh;
  width: 45vw;
  background-color: #fdfdfd;
  border-radius: 1rem;
  align-items: center;
  justify-content: center;
  margin: 50px auto;
  font-family: var(--font-family);
  box-shadow: 0 5px 36px rgba(0, 0, 0, 0.15);
}
.supportai__login-intro {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-bottom: 3rem;
}
.supportai__login-intro h1 {
  font-size: 40px;
}
.supportai__login-intro p {
  font-size: 18px;
  color: #747474;
  margin: 5px;
}
.supportai__login-form {
  position: relative;
  width: 20vw;
  margin-bottom: 3rem;
}
.supportai__login-form form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.supportai__login-form input {
  padding: 10px;
  border: 2px solid #e0e0e0;
  background-color: #e8ebee;
  border-radius: 5px;
  width: 100%;
  transition: border 0.2s ease;
}
.supportai__login-form input:hover {
  border: 2px solid #0052aa;
  transition: border 0.2s ease;
}

.supportai__login-form button {
  padding: 15px;
  background-color: #0052aa;
  color: #fff;
  border: none;
  border-radius: 5px;
  width: 100%;
  cursor: pointer;
  margin-top: 2rem;
  font-weight: 100;
  transition: background-color 0.3s ease;
}

.supportai__login-form button:hover {
  background-color: #0062cc;
}
.MuiAlert-root {
  position: absolute;
  top: 0;
  right: 0;
  margin: 1rem;
  transition: 0.5s;
} 